class formFieldConditions {
  constructor (controlVars) {
    this.controlVars = controlVars
  }
  fields () {
    const {
      sku,
      vcpupoolcount,
      modularFlexpool,
      policies,
      limitChassisQty,
      bandwidthSlider,
      version_range,
      flexpoolquanity,
      matchCoresAndMemory,
      skusForPortsConfig,
      ssl_chipset_type_set,
      basic
    } = this.controlVars

    const commonModularCond = ((sku.flexpool && modularFlexpool) || (sku.modular && !sku.flexpool)) && !sku.trial

    return (
      {
        starts_at: !(sku.trial && sku.platform === 'threatstop'),
        modular_flexpool_checkbox: sku.flexpool && !sku.trial && sku.platform !== 'ngwaf_pool',
        basic: (sku.platform == 'a10c' || sku.platform == 'a10c_ado') && !sku.trial,
        tps: sku.product === 'TPS' && !['tps_detect_cap_sub'].includes(sku.name),
        limit_vcpu_checkbox: !sku.trial && sku.platform && ['vcpu_pool'].includes(sku.platform),
        number_of_vcpus: sku.platform && ['vcpu_pool'].includes(sku.platform) && vcpupoolcount.isActive,
        limit_acos_devices_checkbox: !sku.trial && sku.platform && ['capacity_pool', 'ngwaf_pool'].includes(sku.platform),
        number_of_appliances: !sku.trial && (sku.platform && !['agalaxy', 'a10c_ado', 'cap_visibility', 'capacity_pool', 'vcpu_pool', 'ngwaf_pool'].includes(sku.platform) || sku.platform && ['capacity_pool', 'ngwaf_pool'].includes(sku.platform) && flexpoolquanity.isActive) && !sku.unlimited_devices,
        managed_devices: (sku.platform === 'agalaxy' || sku.platform === 'a10c_ado')&& !sku.trial,
        number_of_partitions: sku && sku.platform === 'agalaxy' && !['agalaxy_tps', 'agalaxy_tps_trial'].includes(sku.name),
        enterprise: sku && sku.flexpool && sku.platform !== 'ngwaf_pool',
        expires_at: sku && !sku.cannot_expire,
        auto_renew: sku && sku.flexpool && !sku.cannot_expire,
        chassis_qty_checkbox: sku && !sku.trial && sku.modular && !['tps_detect_cap_sub'].includes(sku.name),
        license_chasis_quantity: sku && sku.modular && !sku.trial && limitChassisQty,
        license_monitored_ipv4: sku && sku.name === 'defend_threat_control',
        forceful_modular: sku && ['tps_detect_cap_sub'].includes(sku.name),
        acos_version_checkbox: sku && commonModularCond && !['tps_detect_cap_sub'].includes(sku.name),
        version_min_max: sku && commonModularCond && version_range.isActive,
        bandwidth_section: sku && !sku.trial && sku.platform !== 'vcpu_pool' && sku.platform !== 'subscriber_pool' && (sku.flexpool || sku.modular || (sku.platform === 'thunder' && bandwidthSlider.isActive)),
        fp_bandwidth: (!sku.unlimited_bandwidth || sku.modular),
        allocated_bw: sku.platform === 'capacity_pool',
        allocated_bw_ngwaf: sku.platform === 'ngwaf_pool',
        check_in_frequency: sku && sku.flexpool && sku.platform !== 'harmony' && sku.platform !== 'a10c' && sku.platform !== 'ngwaf_pool',
        cthunder_auto_deactivate: sku.platform !== 'harmony' && sku.platform !== 'a10c' && sku.platform !== 'thunder' && !sku.modular && sku.platform !== 'ngwaf_pool',
        pps: commonModularCond && sku.platform !== 'subscriber_pool' && !['tps_detect_cap_sub'].includes(sku.name),
        cores_memory_checkbox: sku.platform === 'capacity_pool',
        core_and_ram: (commonModularCond || sku.modular) && !sku.trial && sku.platform !== 'vcpu_pool' && (sku.platform !== 'capacity_pool' || matchCoresAndMemory) && sku.platform !== 'subscriber_pool',
        ssl_chipset_type: commonModularCond,
        ssl_chipset_count: commonModularCond && ssl_chipset_type_set.isActive,
        spe_present: commonModularCond,
        hardware_blocking: commonModularCond,
        port_selector: sku && commonModularCond && skusForPortsConfig.indexOf(sku.platform) > -1,
        subscriber_count: sku && sku.platform === 'subscriber_pool',
        shell_access: policies.changeShellAccess && sku && !sku.flexpool && sku.family == 'base',
        cthunder_partner: policies.changeShellAccess && sku && !sku.flexpool && sku.family == 'base',
        shell_root_access: policies.changeShellAccess && sku && !sku.flexpool && sku.family == 'base',
        disk_encryption: policies.changeShellAccess && sku && !sku.flexpool && sku.family == 'base',
        hc_entitlements: sku && sku.harmony_modular,
        partner_info: policies.SOA_oracle_support,
        create_multiple_licenses: policies.SOA_oracle_support,
        limit_node_3: sku.trial || basic,
        node_limited: (sku.platform == 'a10c' || sku.platform == 'a10c_ado') && !(sku.product.toLowerCase().includes("saas"))
      }
    )
  }
}

export default formFieldConditions

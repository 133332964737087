import React from 'react'
import RenewalButton from './Buttons/RenewalButton'
import CreateActivationButton from './Buttons/CreateActivationButton'
import UsbTransferButton from './Buttons/UsbTransferButton'
import EditButton from './Buttons/EditButton'
import Link from '../Link'
import Tooltip from '../Tooltip'
/* eslint-disable complexity */
const LicenseRow = ({ licenses, order_id }) => (
  licenses.map(license =>
    <tr key={'license_' + license.id}>
      <td className='ellipsis'>
        <Link to={`/licenses/${license.id}`} children={license.attributes.display_name} />
        <br />
        <div className='fs-small' title={license.attributes.sku_description}>{license.attributes.sku_description}</div>
        {license.attributes.archived ? <span className='label label-danger'>Archived</span> : ''}
        {license.attributes.trial ? <span className='label label-default'>Trial</span> : ''}
        {license.attributes.addon ? <span className='label label-default'>Addon</span> : ''}
        {license.attributes.enterprise && <div className='badge btn-primary'>Enterprise License</div>}
      </td>
      <td className='ellipsis'>
        {(order_id && (license.attributes.order_id !== order_id)) ? <span title={`License has had a new order of ${license.attributes.order} applied`}><i class="fa fa-exclamation-triangle fa-fw"></i></span> : '' }
        {license.attributes.policies.show_order ? (
          license.attributes.fire_eye ? (
            license.attributes.order || <span className='text-muted fs-small'>N/A</span>
          ) : (
            <Link to={license.attributes.order_id ? `/orders/${license.attributes.order_id}/licenses` : '#'} children={license.attributes.order || <span className='text-muted fs-small'>N/A</span>} />
          )
        ) : (license.attributes.organization_id &&
          (license.attributes.policies.org_policy ? (<Link to={`/organizations/${license.attributes.organization_id}`} children={license.attributes.organization_name} />) : (<span>{license.attributes.organization_name}</span>))
        )}
      </td>
      <td>
        {license.attributes.exported ? (
          <span className='label label-default'>Exported</span>
        ) : (license.attributes.number_of_appliances === 0 || license.attributes.platform === 'agalaxy' || license.attributes.platform === 'a10c_ado' || license.attributes.addon) ? (
          <span>-</span>
        ) : (license.attributes.platform === 'threatstop') ? (
          <span>{license.attributes.number_of_appliances}</span>
        ) : (
          <div>
            { license.attributes.capacity_pool
              ? (license.attributes.registered_activations_count)
              : (`${license.attributes.used_activations}`)
            }
            {
              license.attributes.unlimited_devices ? (
                <span>/ Unlimited</span>
              ) : (
                !license.attributes.capacity_pool && (<span>/ {license.attributes.number_of_appliances}</span>)
              )
            }
            {license.attributes.unused_activations && <Tooltip title='This count includes unused one time activation links.' data-html='true'> <i className='fa fa-question-circle' /></Tooltip>}
          </div>
        )
        }
      </td>
      <td>
        <span className='fs-small'>{license.attributes.created_at}</span>
        <br />
        <span className='fs-small black'>
          {license.attributes.policies.show_created_by && license.attributes.created_by_name && (<span>by <Link to={`/users/${license.attributes.created_by_id}`} children={license.attributes.created_by_name} /></span>)}
        </span>
      </td>
      <td><span className='fs-small'>{license.attributes.expires_at || <span className='text-muted'>N/A</span>}</span></td>
      <td>
        <div className='btn-group btn-group-sm pull-right'>
          {license.attributes.policies.show_renew && <RenewalButton licenseId={license.id} />}
          {(license.attributes.bare_metal_usb && license.attributes.policies.usb_transfer) && <UsbTransferButton licenseId={license.id} />}
          {license.attributes.policies.see_creation_activation_link && <CreateActivationButton licenseToken={license.attributes.token} />}
          <EditButton update={license.attributes.policies.update} addon={license.attributes.addon} licenseId={license.id} fireEye={license.attributes.fire_eye} oem_update={license.attributes.policies.oem_update} />
        </div>
      </td>
    </tr>
  )
)
/* eslint-enable complexity */
export default LicenseRow
